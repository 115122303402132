import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
// import { ClientInsightsLandingPageComponent } from '@ei/ei-client-insights';
import {
  AppRoutes as CommonAppRoutes,
  AuthGuard, EnterpriseContextGuard,
  MspContextGuard,
  RouteParams as CommonRouteParams,
} from '@velocloud/angular-vco-platform';
import { AppRoutes, NavigationState } from '@ei/ei-client-platform';

const enterpriseContextRoutes: Route = {
  path: `${CommonAppRoutes.customers}/:${CommonRouteParams.customerId}/${CommonAppRoutes.sdWan}`,
  canActivate: [EnterpriseContextGuard],
  canDeactivate: [EnterpriseContextGuard],
  canActivateChild: [EnterpriseContextGuard],
  children: [
    {
      path: AppRoutes.insights,
      children: [
        {
          path: '',
          loadChildren: () => import('./lazy-libs/ei-client-insights.module').then((m) => m.LazyEiClientInsightsModule),
          data: {
            navigationState: NavigationState.INSIGHTS,
          },
        },
        {
          path: '**',
          pathMatch: 'full',
          redirectTo: AppRoutes.insights,
        },
      ],
    },
  ],
};

const operatorUserRoutes = {
  path: CommonAppRoutes.operator,
  canActivate: [AuthGuard],
  children: [
    enterpriseContextRoutes,
    {
      path: `${CommonAppRoutes.msp}/:${CommonRouteParams.mspId}`,
      canActivate: [MspContextGuard],
      children: [
        enterpriseContextRoutes,
      ],
    },
  ],
};

const partnerUserRoutes = {
  path: CommonAppRoutes.msp,
  canActivate: [AuthGuard],
  children: [
    enterpriseContextRoutes,
  ],
};

const enterpriseUserRoutes: Route = {
  path: CommonAppRoutes.sdWan,
  canActivate: [EnterpriseContextGuard],
  canDeactivate: [EnterpriseContextGuard],
  canActivateChild: [EnterpriseContextGuard],
  children: [
    {
      path: AppRoutes.insights,
      children: [
        {
          path: '',
          loadChildren: () => import('./lazy-libs/ei-client-insights.module').then((m) => m.LazyEiClientInsightsModule),
          data: {
            navigationState: NavigationState.INSIGHTS,
          },
        },
        {
          path: '**',
          pathMatch: 'full',
          redirectTo: AppRoutes.insights,
        },
      ],
    },
  ],
};

const appRoutes: Routes = [
  operatorUserRoutes,
  partnerUserRoutes,
  enterpriseUserRoutes,
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    paramsInheritanceStrategy: 'always',
    // enableTracing: true, // for debug purposes
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
