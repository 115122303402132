/* eslint-disable max-len */
export const ENGLISH = {
  'ei.insights.lp.linkInsights': 'Link Insights',
  'ei.insights.lp.geoDistrib': 'Incident Geo Distribution',
  'ei.insights.lp.geoDistribOn': 'Map View On',
  'ei.insights.lp.timeDistrib': 'Incident Time Distribution',
  'ei.insights.lp.timeDistribOn': 'Time Distribution On',
  'ei.insights.lp.involvedIsps': 'Involved ISPs',
  'ei.insights.lp.incidentImpact': 'Incident Impact',
  'ei.insights.lp.edgeIncidents': 'Most Incidents on - Edges',
  'ei.insights.lp.linkIncidents': 'Most Incidents on - Links',
  'ei.insights.lp.involvedApps': 'Involved Applications',
  'ei.insights.lp.wanExits': 'WAN Exits',
  'ei.insights.lp.summaryText': '12 unique links across 8 edges encountered performance degradation', // TODO: Pluralize properly.
  'ei.insights.lp.now': 'now',
  'ei.insights.lp.sortByTime': 'Sort By Time',
  'ei.insights.lp.trafficDistribution': 'Traffic Distribution',
  'ei.insights.lp.numberOfIncidents': '{0} Incidents',
  'ei.insights.lp.incidentsByLocation': 'Incidents by Location',
  'ei.insights.lp.numberOfLocations': '{0} Locations',
  'ei.insights.lp.other': 'Other',
  'ei.insights.lp.viewDetails': 'View Details',
  'ei.insights.lp.links': 'Links',
  'ei.insights.lp.edges': 'Edges',
  'ei.insights.lp.locations.title': 'Incidents - All Locations',
  'ei.insights.lp.locations.table.title': 'Location List',
  'ei.insights.lp.locations.column.location': 'Location',
  'ei.insights.lp.locations.column.time': 'Time',
  'ei.insights.lp.locations.column.incident': 'Incident',
  'ei.insights.lp.locations.column.edge': 'Edge',
  'ei.insights.lp.locations.column.link': 'Link',
  'ei.insights.lp.locations.column.applications': 'Applications',
  'ei.insights.lp.locations.column.applications.title': '{0} Applications',
  'ei.insights.lp.locations.table.empty': 'No data about locations',
  'ei.insights.lp.type.jitter': 'Jitter',
  'ei.insights.lp.type.packetLoss': 'Packet Loss',
  'ei.insights.lp.type.latency': 'Latency',
  'ei.insights.lp.type.mixed': 'Mixed',
};
