export enum LandingPageRoutes {
  dashboard = 'dashboard',
}

export enum AppRoutes {
  insights = 'insights'
}

export enum InsightsRoutes {
  linkPerformance = 'link-performance'
}

export enum RouteParams {
  insightId = 'insightId',
}
