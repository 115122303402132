import { Component, OnInit } from '@angular/core';
import {
  angleDoubleIcon,
  angleIcon,
  arrowIcon,
  banIcon,
  barsIcon,
  bookIcon,
  bubbleExclamationIcon,
  bullseyeIcon,
  calendarIcon,
  checkCircleIcon,
  checkIcon,
  circleArrowIcon,
  ClarityIcons,
  cloneIcon,
  cloudTrafficIcon,
  compassIcon,
  copyIcon,
  downloadIcon,
  dragHandleIcon,
  ellipsisHorizontalIcon,
  ellipsisVerticalIcon,
  errorStandardIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  expandCardIcon,
  eyeHideIcon,
  eyeIcon,
  fileSettingsIcon,
  filterGridIcon,
  filterIcon,
  flameIcon,
  folderIcon,
  gridChartIcon,
  hardDiskIcon,
  helpIcon,
  helpInfoIcon,
  historyIcon,
  hourglassIcon,
  infoCircleIcon,
  infoStandardIcon,
  keyIcon,
  listIcon,
  lockIcon,
  mapMarkerIcon,
  minusCircleIcon,
  minusIcon,
  networkGlobeIcon,
  networkSwitchIcon,
  noteIcon,
  pencilIcon,
  pinIcon,
  plusCircleIcon,
  plusIcon,
  popOutIcon,
  printerIcon,
  refreshIcon,
  repeatIcon,
  searchIcon,
  shieldCheckIcon,
  shieldIcon,
  starIcon,
  stepForward2Icon,
  successStandardIcon,
  tasksIcon,
  timesCircleIcon,
  timesIcon,
  trashIcon,
  undoIcon,
  uploadCloudIcon,
  uploadIcon,
  userIcon,
  usersIcon,
  viewListIcon,
  warningStandardIcon,
  windowCloseIcon,
  wrenchIcon,
} from '@cds/core/icon';
import { ClrCommonStringsService } from '@clr/angular';
import {
  I18nUtilsService,
  PageTitleService,
  VcoValidationService,
  WalkawayMonitorService,
} from '@velocloud/angular-vco-platform';
import { BehaviorSubject } from 'rxjs';
import { Unsubscriber } from '@velocloud/angular-vc-common';

@Component({
  selector: 'ei-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends Unsubscriber implements OnInit {
  private loadingSource = new BehaviorSubject<boolean>(true);

  public loading$ = this.loadingSource.asObservable();

  public title = 'maestro-licensing-mfe';

  public constructor(
    private I18nUtils: I18nUtilsService,
    private clrCommonStringsService: ClrCommonStringsService,
    private walkawayMonitorService: WalkawayMonitorService,
    private pageTitleService: PageTitleService,
    private vcoValidationService: VcoValidationService,
  ) {
    super();
  }

  private static initIcons(): void {
    ClarityIcons.addIcons(
      angleDoubleIcon,
      angleIcon,
      arrowIcon,
      banIcon,
      barsIcon,
      bookIcon,
      bubbleExclamationIcon,
      bullseyeIcon,
      calendarIcon,
      checkCircleIcon,
      checkIcon,
      circleArrowIcon,
      cloneIcon,
      cloudTrafficIcon,
      compassIcon,
      copyIcon,
      downloadIcon,
      dragHandleIcon,
      ellipsisHorizontalIcon,
      ellipsisVerticalIcon,
      errorStandardIcon,
      exclamationCircleIcon,
      exclamationTriangleIcon,
      expandCardIcon,
      eyeHideIcon,
      eyeIcon,
      fileSettingsIcon,
      filterGridIcon,
      filterIcon,
      flameIcon,
      folderIcon,
      gridChartIcon,
      hardDiskIcon,
      helpIcon,
      helpInfoIcon,
      historyIcon,
      hourglassIcon,
      infoCircleIcon,
      infoStandardIcon,
      keyIcon,
      listIcon,
      lockIcon,
      mapMarkerIcon,
      minusCircleIcon,
      minusIcon,
      networkGlobeIcon,
      networkSwitchIcon,
      noteIcon,
      pencilIcon,
      pinIcon,
      plusCircleIcon,
      plusIcon,
      popOutIcon,
      printerIcon,
      refreshIcon,
      repeatIcon,
      searchIcon,
      shieldCheckIcon,
      shieldIcon,
      starIcon,
      stepForward2Icon,
      successStandardIcon,
      tasksIcon,
      timesCircleIcon,
      timesIcon,
      trashIcon,
      undoIcon,
      uploadCloudIcon,
      uploadIcon,
      userIcon,
      usersIcon,
      viewListIcon,
      warningStandardIcon,
      windowCloseIcon,
      wrenchIcon,
    );
  }

  public ngOnInit(): void {
    AppComponent.initIcons();

    if (this.vcoValidationService.isPortalValid) {
      this.initServices();
    }

    this.loadingSource.next(false);
  }

  private initServices(): void {
    this.pageTitleService.init();
    this.walkawayMonitorService.init();
    this.clrCommonStringsService.localize(this.I18nUtils.clrCommonStrings);
  }
}
