import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { APP_BASE_HREF, LocationStrategy } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import {
  VIPModule, VIPService, LocaleService,
} from '@vmw/ngx-vip';
import {
  VcoPlatformModule,
  BrandingService,
  VcoValidationService,
  initBranding,
  DomainPathLocationStrategy,
} from '@velocloud/angular-vco-platform';
import {
  APP_PRIVILEGE_KEY_TOKEN, APP_PRIVILEGE_KEYS,
} from '@velocloud/angular-vco-api';
import { EiClientPlatformModule, initVIPConfig } from '@ei/ei-client-platform';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

/**
 *
 * @param vcoValidationService
 */
export function validatePortal(vcoValidationService: VcoValidationService): () => Promise<boolean> {
  return () => vcoValidationService.validatePortal();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    VcoPlatformModule,
    EiClientPlatformModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'velocloud.csrf',
      headerName: 'x-vco-csrf',
    }),
    VIPModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: validatePortal,
      deps: [
        VcoValidationService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initVIPConfig,
      deps: [
        VIPService,
        LocaleService,
      ],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/ui/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initBranding,
      deps: [
        BrandingService,
      ],
      multi: true,
    },
    {
      provide: APP_PRIVILEGE_KEY_TOKEN,
      useValue: APP_PRIVILEGE_KEYS.SDWAN,
    },
    {
      provide: LocationStrategy,
      useClass: DomainPathLocationStrategy,
    },
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
}
