import {
  getBrowserCultureLang,
  LocaleService,
  PatternCategories,
  VIPService,
} from '@vmw/ngx-vip';
import { ENGLISH as ENGLISH_VCO_UI, ApplicationEnumMessages } from '@velocloud/angular-vco-platform';
import { ENGLISH as ENGLISH_EI_UI } from '../i18n/ei-ui.l10n';

/**
 * Inits VMW's VIP translation service.
 * Lovingly stolen from SSE's app bootstrap.
 * @param vipService The VIP service singleton.
 * @param localeService The locale service singleton.
 * @returns A configuration promise.
 */
export function initVIPConfig(
  vipService: VIPService,
  localeService: LocaleService,
): () => Promise<any> {
  const currentLocale: string = getBrowserCultureLang();

  localeService.init(currentLocale);

  const vcoHostName: string = window.location.hostname;
  const i18nServiceUrl = `https://${vcoHostName}/i18n/`;

  return (() => {
    // Fall back to SD-WAN shared strings
    vipService.registerComponent({
      productID: 'VeloCloudOrchestrator',
      component: 'VcoUI',
      version: '99.0.0',
      i18nScope: [
        PatternCategories.DATE,
        PatternCategories.NUMBER,
        PatternCategories.PLURAL,
        PatternCategories.CURRENCIES,
        PatternCategories.DATEFIELDS,
      ],
      host: i18nServiceUrl,
      isPseudo: false,
      collectSource: false,
      sourceBundles: [ENGLISH_VCO_UI, ApplicationEnumMessages],
      isolated: false,
      timeout: 5000,
    });

    // Look first in microfrontend strings
    return vipService.initData({
      productID: 'VcoEi',
      component: 'EiUi',
      version: '99.0.0',
      i18nScope: [
        PatternCategories.DATE,
        PatternCategories.NUMBER,
        PatternCategories.PLURAL,
        PatternCategories.CURRENCIES,
        PatternCategories.DATEFIELDS,
      ],
      host: i18nServiceUrl,
      isPseudo: false,
      collectSource: false,
      sourceBundles: [ENGLISH_EI_UI],
      timeout: 5000,
    });
  });
}
