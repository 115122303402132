import { VCColors } from '@velocloud/angular-vco-api';
import { CSSObject, Options } from 'highcharts';

/**
 * Shared chart configuration that applies to all charts in the Insights landing page.
 */
export const sharedChartConfig: Options = {
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  chart: {
    backgroundColor: 'transparent',
  },
  title: {
    text: undefined,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    formatter(): string {
      if (this.key) {
        return `${this.key} (${this.y})`;
      }
      return `${this.y}`;
    },
  },
};

export const colorSeries = [
  '#DB4437',
  '#7B1FA2',
  '#F29900',
  '#4285F4',
  VCColors.lightGray,
];

/**
 * The styling of individual legend items.
 */
export const itemStyle: CSSObject = {
  fontFamily: 'Metropolis',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '16px',
  letterSpacing: '-0.1px',
};
